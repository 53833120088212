.inner{
  margin-top: 40px;
  margin-left: 24px;
  margin-right: 16px;
  display: flex;

}
.tableInner{
  background: #FFFFFF;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  width: 100%;
  overflow: auto;
  &__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__headerButtons{
    margin-right: 16px;
  }
}
.tableTitle{
  color: var(--Grey-Grey-90, #111827);
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 180% */
  margin: 16px 0 19px 16px;
}
.search_input {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #9CA3AF;
  border: none;
  width: 270px;
  height: 32px;
  outline: none;
}
.rowsPerPage{
  height: 40px;
  margin: 0;
  border-top: none;
  border-bottom: none;
  font-family: Inter,sans-serif!important;
  font-weight: 500;
  color: #1D2939;
  font-size: 14px;
  line-height: 20px;
  border-radius: 0;
}
.footerText{
  margin-bottom: 0;
  font-family: Inter,sans-serif!important;
  font-weight: 500;
  color: #1D2939;
  font-size: 14px;
  line-height: 20px;
  margin-right: 10px;
}
.columnHeader {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #6B7280;
}
.editReq{
  color: var(--Grey-Grey-90, #111827);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border: none;
  outline: none;
  background-color: transparent;
}
.addActivityBtn {
  border-radius: 8px;
  border: 1px solid #1755E7;
  background: #1755E7;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white, #FFF);
  text-align: center;
  font-family: Inter, sans-serif;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  max-width: 113px;
  width: 100%;
  height: 32px;
  margin-right: 26px;
  cursor: pointer;

  &[disabled] {
    color: var(--white, #FFF);
  }
}