.inner{
  margin: 0 auto;
  max-width: 1140px;
  width: 100%;
  position: relative;
  padding: 0 0 20px 0;
}
.header{
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 55px;
  border-bottom: 1px solid rgb(237, 28, 36);
  &__logo{
    max-width: 290px;
    max-height: 80px;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
.actions{
  display: flex;
  margin-top: 25px;
  align-items: center;
  justify-content: flex-end;
  button{
    background-color: transparent;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.links{
  display: flex;
  justify-content: center;
  padding-bottom: 55px;
  gap: 150px;
  &__link{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    p{
      text-align: center;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 150% */
      margin: 12px 0 12px;
    }
    a{
      text-align: center;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }
}