.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1{
    color: var(--Grey-Grey-90, #111827);
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
  }
}
.badge{
  color: var(--Gray-700, #344054);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding: 2px 8px;
  border-radius: 16px;
  background: var(--Gray-100, #F2F4F7);
}
.form{
  &__row{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    gap: 6px;
    label{
      color: var(--Gray-700, #344054);
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
    input{
      border-radius: 8px;
      border: 1px solid var(--Gray-300, #D0D5DD);
      background: var(--White, #FFF);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: var(--Gray-500, #344054);
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      height: 44px;
      &::placeholder{
        color: #667085;
        opacity: 0.6;
      }
    }
    & + & {
      margin-top: 16px;
    }
  }
  &__rowDouble{
    display: flex;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 16px;
    .form__row + .form__row{
      margin-top: 0;
    }
  }
  &__checkbox{
    color: #000;
    font-family: Inter, sans-serif;
    font-size: 14.297px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.map{
  margin-top: 16px;
}
.prahaBlock{
  &__checkBoxGroup{
    display: flex;
    flex-direction: row!important;
    flex-wrap: wrap;
    row-gap: 12px;
    column-gap: 60px;
    margin-bottom: 16px;
    span{
      color: #000;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &__title{
    color: var(--Grey-Grey-90, #111827);
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 180% */
    margin-bottom: 20px;
  }
  &__radioGroup{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 34px;
    gap: 10px;

  }
  &__checkboxes{
    display: flex;
    flex-direction: column;
    gap: 12px;
    span{
      color: #000;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
.parkingCheckboxes > div:first-child{
  display: flex;
  gap: 125px;
}
.parkingCheckboxes{
  margin-bottom: 20px;
  span{
    color: #000;
    font-family: Inter, sans-serif;
    font-size: 14.297px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.addBoxes{
  &__title{
    color: var(--Gray-700, #344054);
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
    margin-bottom: 8px;
  }
}
.buttons{
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 52px;
}

.dispoziceCheckboxes{
  display: flex;
  flex-direction: column;
}
.comboCurrency{
  display: flex;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #D0D5DD);
  background: var(--White, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.squarePlaceholder{
  position: absolute;
  color: var(--color-for-email, #161616);
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  width: 62px;
  height: 42px;
  background: var(--White, #FFF);
  border-radius: 8px;
  right: 1px;
  top: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: none;
}
.error{
  font-size: 12px;
  color: var(--gray-700, #ff0000);
  margin-bottom: 0;
}
.form{
  &__asterics{
    display: flex;
    align-items: center;
    gap: 12px;
    p{
      color: var(--secondary-sidebar, #A0A0A0);
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 0;
    }
    span{
      color: var(--accent, #0707FA);
    }
  }
}

.note{
  margin-top: 15px;
  p{
    color: var(--secondary-sidebar, #A0A0A0);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 0;
  }
}
.linkedProperties {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 8px;
  border: 1px solid var(--Gray-200, #EAECF0);
  background: #F9FAFB;
  box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  &::-webkit-scrollbar-thumb {
    background-color: #EAECF0; /* Adjust the color of the scrollbar thumb */
    border-radius: 12px; /* Adjust the border radius as needed */
  }

  &::-webkit-scrollbar {
    width: 10px; /* Adjust the width of the scrollbar */
    background-color: transparent; /* Adjust as needed */
    border-radius: 12px;
  }
  &__title{
    color: var(--Gray-900, #101828);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin-top: 8px;
    margin-left: 24px;
    margin-bottom: 2px;
  }
  &__table{
    display: flex;
    border-bottom: 1px solid #E5E7EB;
    &:last-child{
      border-bottom: none;
    }
  }
  &__tableColumn{
    flex: 1;
    color: var(--color-for-email, #161616);
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 5px 12px;
    &_header{
      flex: 1;
      color: var(--Gray-500, #667085);
      font-family: Inter, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      padding: 5px 12px;
    }
  }
}
@media (max-width: 768px) {
  .header{
    h1{
      margin: 30px 0 40px;
    }
  }
}
@media (max-width: 480px) {
  .prahaBlock {
    &__checkBoxGroup {
      column-gap: 20px;
    }
  }
  .form{
    &__rowDouble{
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 16px;
      .form__row + .form__row{
        margin-top: 0;
      }
    }
  }
  .buttons{
    flex-direction: column-reverse;
  }
}

